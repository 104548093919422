exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-policies-policies-jsx": () => import("./../../../src/pages/policies/policies.jsx" /* webpackChunkName: "component---src-pages-policies-policies-jsx" */),
  "component---src-pages-policies-privacy-jsx": () => import("./../../../src/pages/policies/privacy.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-jsx" */),
  "component---src-pages-policies-shipping-jsx": () => import("./../../../src/pages/policies/shipping.jsx" /* webpackChunkName: "component---src-pages-policies-shipping-jsx" */),
  "component---src-pages-policies-tos-jsx": () => import("./../../../src/pages/policies/tos.jsx" /* webpackChunkName: "component---src-pages-policies-tos-jsx" */),
  "component---src-pages-products-shopify-product-product-type-index-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/index.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-index-jsx" */),
  "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx": () => import("./../../../src/pages/products/{ShopifyProduct.productType}/{ShopifyProduct.handle}.jsx" /* webpackChunkName: "component---src-pages-products-shopify-product-product-type-shopify-product-handle-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

