import * as React from "react"
import Client from "shopify-buy"

const client = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_CUSTOM_DOMAIN,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  },
  fetch
)

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => { },
  onClose: () => { },
  addVariantToCart: () => { },
  removeLineItem: () => { },
  updateLineItem: () => { },
  client,
  checkout: {
    lineItems: [],
  },
}

export const StoreContext = React.createContext(defaultValues)

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(defaultValues.checkout)
  const [loading, setLoading] = React.useState(false)
  const [didJustAddToCart, setDidJustAddToCart] = React.useState(false)

  const setCheckoutItem = (checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await client.checkout.fetch(
            existingCheckoutID
          )
          if (!existingCheckout.completedAt) {
            setCheckoutItem(existingCheckout)
            return
          }
        } catch (e) {
          localStorage.setItem(localStorageKey, null)
        }
      }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }

    initializeCheckout()
  }, [])

  const addVariantToCart = (variantId, quantity, letterColor, letter, freeformNames, singleName, nameColor, stainColor, bankBackgroundColor, bankLetterBorderColor, bankNameBackgroundColor, bankNameForegroundColor, standingFamilyColor, standingFamilyPerson1Option, standingFamilyPerson2Option, sportsVariant, sportsColor, sportsIcon, sportsBorder, sportsName, sportsNumber, sportsTeam, armedForcesBranch, armedForcesName, namePrefixOption) => {
    setLoading(true)

    const checkoutID = checkout.id
    let customAttributes = [];
    let lineItemsToUpdate;

    if (letterColor !== "") {
      customAttributes.push(
        {
          key: "Letter Color",
          value: letterColor
        });
    }
    if (letter !== "") {
      customAttributes.push({
        key: "Letter",
        value: letter
      })
    }
    if (freeformNames !== "") {
      customAttributes.push(
        {
          key: "Names",
          value: freeformNames
        }
      )
    }
    if(namePrefixOption !== "") {
      customAttributes.push(
        {
          key:"Name Prefix",
          value: namePrefixOption
        }
      )
    }
    if (singleName !== "") {
      customAttributes.push(
        {
          key: "Name",
          value: singleName
        }
      )
    }
    if (nameColor !== "") {
      customAttributes.push(
        {
          key: "Name Color",
          value: nameColor
        }
      )
    }
    if (stainColor !== "") {
      customAttributes.push(
        {
          key: "Stain Color",
          value: stainColor
        }
      )
    }
    if (bankBackgroundColor !== "") {
      customAttributes.push(
        {
          key: "Bank Background Color",
          value: bankBackgroundColor
        }
      )
    }
    if (bankLetterBorderColor !== "") {
      customAttributes.push(
        {
          key: "Bank Letter Border Color",
          value: bankLetterBorderColor
        }
      )
    }
    if (bankNameBackgroundColor !== "") {
      customAttributes.push(
        {
          key: "Bank Name Background Color",
          value: bankNameBackgroundColor
        }
      )
    }
    if (bankNameForegroundColor !== "") {
      customAttributes.push(
        {
          key: "Bank Name Foreground Color",
          value: bankNameForegroundColor
        }
      )
    }
    if (standingFamilyColor !== "") {
      customAttributes.push(
        {
          key: "Standing Family Color",
          value: standingFamilyColor
        }
      )
    }
    if (standingFamilyPerson1Option !== "") {
      customAttributes.push(
        {
          key: "Standing Family Person 1 Option",
          value: standingFamilyPerson1Option
        }
      )
    }
    if (standingFamilyPerson2Option !== "") {
      customAttributes.push(
        {
          key: "Standing Family Person 2 Option",
          value: standingFamilyPerson2Option
        }
      )
    }
    if (sportsVariant !== "") {
      customAttributes.push(
        {
          key: "Gender and Sport",
          value: sportsVariant
        }
      )
    }
    if (sportsColor !== "") {
      customAttributes.push(
        {
          key: "Background Color",
          value: sportsColor
        }
      )
    }
    if (sportsIcon !== "") {
      customAttributes.push(
        {
          key: "Icons",
          value: sportsIcon
        }
      )
    }
    if (sportsBorder !== "") {
      customAttributes.push(
        {
          key: "Border",
          value: sportsBorder
        }
      )
    }
    if (sportsName !== "") {
      customAttributes.push(
        {
          key: "Player Name",
          value: sportsName
        }
      )
    }
    if (sportsNumber !== "") {
      customAttributes.push(
        {
          key: "Player Number",
          value: sportsNumber
        }
      )
    }
    if (sportsTeam !== "") {
      customAttributes.push(
        {
          key: "Player Team",
          value: sportsTeam
        }
      )
    }
    if (armedForcesBranch !== "") {
      customAttributes.push(
        {
          key: "Armed Forces Branch",
          value: armedForcesBranch
        }
      )
    }

    if (armedForcesName !== "") {
      customAttributes.push(
        {
          key: "Armed Forces Name",
          value: armedForcesName
        }
      )
    }

   


    lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
        customAttributes
      },
    ]

    return client.checkout
      .addLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
        setDidJustAddToCart(true)
        setTimeout(() => setDidJustAddToCart(false), 3000)
      })
  }

  const removeLineItem = (checkoutID, lineItemID) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID, lineItemID, quantity) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        removeLineItem,
        updateLineItem,
        checkout,
        loading,
        didJustAddToCart,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
